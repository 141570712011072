var m = require("mithril")
var AppState = require("../services/AppState");
var Auth = require("../services/Auth");


class ChildEilandday {

    constructor() {
     this.list =  [];
     this.status = "Stabiel";
     this.date_filter = null;
     this.childeiland_today_list = [];
     this.childeiland_daytype_today_list = [];
     this.childeiland_today_count = 0;

     this.loadOnEilandByDate = this.loadOnEilandByDate.bind(this);
     this.loadOnEilandByDateSuccess = this.loadOnEilandByDateSuccess.bind(this);

     this.my_childeiland_list = [];
     this.my_childeiland_count = 0;
     this.loadMyChildEilanddaysForChild = this.loadMyChildEilanddaysForChild.bind(this);
     this.receiveMyChildEilanddaysForChild = this.receiveMyChildEilanddaysForChild.bind(this);

     this.getChildEilanddaysForChild = this.getChildEilanddaysForChild.bind(this);

     // ChildEilanddayPresence has OneOnOne relation with ChilEilandday
     this.createChildEilanddayPresence = this.createChildEilanddayPresence.bind(this);
     this.createChildEilanddayPresenceSucces = this.createChildEilanddayPresenceSucces.bind(this);
     this.createChildEilanddayPresenceFail = this.createChildEilanddayPresenceFail.bind(this);
    }

    loadOnEilandByDateSuccess(result) {
        this.childeiland_today_count = result.count;
        this.childeiland_today_list = result.results;
        this.status = "Data Geladen";
        // _today_list is de 'parent'
        // nu uitpakken naar _daytype_today_list

        this.date_filter = AppState.get_filter_date();
    }

    loadOnEilandByDate() {
        if(this.date_filter == AppState.get_filter_date()) {
            return
        }
        this.childeiland_today_count = 0;
        this.childeiland_today_list = [];
        this.status = "Ophalen Voorbereiden";
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/child-eilandday/"+AppState.get_filter_date()+"/"
            // todo: naar onderstaande format. API moet eerst.
            // url: Auth.domain+"/api/child-eilandday/?day="+AppState.get_filter_date()
            // url: Auth.domain+"/api/child-eilandday/?date="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        this.status = "Ophalen Data";
        return m.request(request_data)
                .then(this.loadOnEilandByDateSuccess)
    }

    createChildEilanddayPresence(childeilanddayId) {
        // console.log("createChildEilanddayPresence("+childeilanddayId+")");
        let formData = new FormData();
        formData.append('childeilandday', childeilanddayId);
        formData.append('crew', '1'); // wordt hergevuld op de server. id moet wel bestaan
        const request_data = {
            method: "POST",
            url: Auth.getDomain()+"/api/childeilanddaypresent/",
            body: formData,
            extract: (xhr) => {return {status: xhr.status, body: JSON.parse(xhr.responseText)}}
        }
        Auth.authorize_request_data(request_data);
        m.request(request_data)
         .then(this.createChildEilanddayPresenceSucces)
         .catch(this.createChildEilanddayPresenceFail);
         // .catch((e) => { console.error("ChildEilanddayPresenceCreate Error: "+e);
         //                })
    }
    createChildEilanddayPresenceSucces(result) {
        // console.log("createChildEilanddayPresenceSucces result: "+result.body );
        // Als status == 201 (created)
        //   Loop over  this.childeiland_today_list
        //     Als: childeilandday.id gevonden:
        //     Dan: zet is_present locaal op op True
        if(result.status==201) {
            this.childeiland_today_list.forEach((childeilandday)=>{
                if(childeilandday.id == result.body.childeilandday) {
                    childeilandday.is_present = true;
                }
            })
        }
        // m.redraw(); hoeft niet, request activeert m.redraw() als die klaar is
    }
    createChildEilanddayPresenceFail(result) {
        console.error("error result: "+result);
    }

    getChildEilanddaysForChild(childId) {
        let child_childeiland_list = [];
        this.childeiland_today_list.forEach((childeilandday)=>{
            if(childeilandday.child == childId) {
                child_childeiland_list.push(childeilandday);
            }
        })
        // console.warn("// reverse zet de tijden toevallig goed, maar dat blijft een gokje. implementeer sort(), of doe het helemaal niet");
        return child_childeiland_list //.reverse()
    }

    receiveMyChildEilanddaysForChild(result) {
        this.my_childeiland_count = result.count
        this.my_childeiland_list = result.results
        this.status = "Data Geladen";
    }

    // Voor Cateaker om rooster van kind op te halen
    loadMyChildEilanddaysForChild(childId) {
        this.childeiland_today_count = 0;
        this.childeiland_today_list = [];
        this.status = "Ophalen Voorbereiden";
        var request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/my-child/"+childId+"/schedule/"
            // todo: naar onderstaande format. API moet eerst.
            // url: Auth.domain+"/api/child-eilandday/?day="+AppState.get_filter_date()
            // url: Auth.domain+"/api/child-eilandday/?date="+AppState.get_filter_date()
        }
        Auth.authorize_request_data(request_data);
        this.status = "Ophalen Data";
        return m.request(request_data)
                .then(this.receiveMyChildEilanddaysForChild)
    }


}


module.exports = new ChildEilandday()

