var m = require("mithril")
var Auth = require("../services/Auth")
var AppState = require("../services/AppState")

// Of is het meer een Service??

class OptionValueMap {

    constructor() {
        this.list =  [];
        this.count = 0;
        this._receiveOptionValueList = this._receiveOptionValueList.bind(this);
        this.loadList = this.loadList.bind(this);
        this.getOptionValue = this.getOptionValue.bind(this);
        // this.loadList(); wordt elders aangeroepen.
    }

    _receiveOptionValueList(result) {
        this.count = result.count;
        this.list = result.results;
    }

    loadList() {
        const request_data = {
            method: "GET",
            url: Auth.getDomain()+"/api/optionvalue-all/",
        }
        Auth.authorize_request_data(request_data);
        return m.request(request_data)
                .then(this._receiveOptionValueList)
                .catch((e) => {
                        console.debug("loadList Error: "+e);
                        const dummyResult = { count: 5,
                                                results: [ {name: "foo", label:"Foo", type: "text"}
                                                          ,{name: "foofoo", label:"Foo Foo", type: "checkbox"}
                                                          ,{name: "daytype_filter", label: "Filter Groepen", type: "dropdown",
                                                            options: [[-1,"Is Nog Leeg"], [5, "School De Pol"]] }
                                                          ,{name: "child_order", label: "Sortering Kinderen", type: "dropdown",
                                                            options: [[0,"Aankomst op EvG"], [1,"Voornaam"], [2,"Leeftijd"]]}
                                                          ,{name: "menu_order", label: "Sortering Menu", type: "dropdown",
                                                            options: [[0,"Vaste Plek"], [1,"Meest Gebruikt Boven"], [2,"Mijn Sortering"]]}
                                                            ]
                        }
                        console.debug("loadList dummy: "+dummyResult);
                        this._receiveOptionValueList(dummyResult);

                       })
    }

    getOptionValue(name) {
         try {
             for (let i = 0; i < this.list.length-1; i++) {
                  if( this.list[i].name == name) {
                     return this.list[i]
                  }
             }
             console.info("OptionValue '"+name+"' Niet Gevonden: ");
         } catch (e) {
            console.error("Fout Vinden OptionValue '"+name+"': "+e);
         }
         return null
    }


}

module.exports = new OptionValueMap()
