var m = require("mithril")
var AppState = require("../services/AppState")
var ChildImgName = require("./ChildImgName")
var ChildEilandday = require("../models/ChildEilandday")
var CrewEilandday = require("../models/CrewEilandday")


class ChildEilanddayListItem {

    constructor(vnode) {
       this.render_item_data_new = this.render_item_data_new.bind(this);
       this.render_item_data = this.render_item_data.bind(this);
    }


    render_item_data(_childeilandday) {
        let item_data = []
        let item_data_0 = []
        let item_data_1 = []
        let item_data_2 = [] // Gekadere Activiteiten

        if (AppState.daytypeFilter == 0 ) {
             let actres_count = null;
             if(_childeilandday.activityresource_list?.length>0) {
                if(_childeilandday.activityresource_list.length==1){
                    actres_count = m('span.activity', {style: "display: block; font-size: x-small"},
                                     "(1 Gekaderde Activiteit)");
                } else {
                    actres_count = m('span.activity', {style: "display: block; font-size: x-small"},
                                     "("+_childeilandday.activityresource_list.length+" Gekaderde Activiteiten)");
                }
             }
             item_data_2.push( actres_count)
        } else {
             _childeilandday.activityresource_list.forEach( activityresource => {
                 const act_name_time = activityresource.activity_name+"("+activityresource.start_time_short+"), "
                 item_data_2.push( m('span.activity', act_name_time));
             });
             if (item_data_2.length == 0) {
                 item_data_2.push( m('span.activity', "Geen Gekaderde Activiteiten"));
             }
        }


        // Ophalen Andere Dagsoorten voor dit kind
        // let _childeilandday_list = ChildEilandday.getChildEilanddaysForChild(_childeilandday.child); // child is childId
        // _childeilandday_list.sort((a, b) => (a.delivery_time_short > b.delivery_time_short) ? 1 : -1)
        let _childeilandday_list = _childeilandday.daytype_list;
        _childeilandday_list.sort((a, b) => (a.delivery_time_short > b.delivery_time_short) ? 1 : -1);
        _childeilandday_list.forEach( _ced => {
           let _ced_creweiland_list = null;
           if (AppState.daytypeFilter != 0 &&
               _ced.day_type!=AppState.daytypeFilter) {
               // alleen ophalen als dagsoortfilter actief en dagsoort ongelijk is aan het dagsoortfilter
              _ced_creweiland_list = CrewEilandday.getCrewEilandByDaytypeTime(_ced.day_type, "00:00");
           }
           let daytype_data = []
           const day_start_end_time = _ced.delivery_time_short+'-'+_ced.pickup_time_short
           let crew_first_name = ((_ced_creweiland_list?.length>=1) ? " ("+_ced_creweiland_list[0].first_name+")" : "");
           daytype_data.push( m('.pull-left.daytype', _ced.day_type_name+crew_first_name ))
           daytype_data.push( m('.pull-right.start_end_time', day_start_end_time))
           item_data.push(m('.pull-left.list-data-max-width', daytype_data));
        })

        item_data.push(m('.pull-left.list-data-max-width', item_data_2)); // Gekaderde Activiteiten
        return item_data
    }

    render_item_data_new(_childeilandday) {
        let item_data = [];
        let item_data_0 = [];
        let item_data_1 = [];
        let item_data_2 = []; // Gekadere Activiteiten

        if (AppState.daytypeFilter == 0 ) {
             let actres_count = null;
             if(_childeilandday.activityresource_list?.length>0) {
                if(_childeilandday.activityresource_list.length==1){
                    actres_count = m('span.activity', {style: "display: block; font-size: x-small"},
                                     "(1 Gekaderde Activiteit)");
                } else {
                    actres_count = m('span.activity', {style: "display: block; font-size: x-small"},
                                     "("+_childeilandday.activityresource_list.length+" Gekaderde Activiteiten)");
                }
             }
             item_data_2.push( actres_count)
        } else {
             _childeilandday.activityresource_list.forEach( activityresource => {
                 const act_name_time = activityresource.activity_name+"("+activityresource.start_time_short+"), "
                 item_data_2.push( m('span.activity', act_name_time));
             });
             if (item_data_2.length == 0) {
                 item_data_2.push( m('span.activity', "Geen Gekaderde Activiteiten"));
             }
        }


        // Ophalen Andere Dagsoorten voor dit kind
        //let _childeilandday_list = ChildEilandday.getChildEilanddaysForChild(_childeilandday.child); // child is childId
        //_childeilandday_list.sort((a, b) => (a.delivery_time_short > b.delivery_time_short) ? 1 : -1);
        let _childeilandday_list = _childeilandday.daytype_list;
        _childeilandday_list.sort((a, b) => (a.delivery_time_short > b.delivery_time_short) ? 1 : -1);
        console.log("_childeilandday_list: "+_childeilandday_list);
        _childeilandday_list.forEach( _ced => {
           console.log("_ced.day_type_name: "+_ced.day_type_name);
           let _ced_creweiland_list = null;
           if (AppState.daytypeFilter != 0 &&
               _ced.day_type!=AppState.daytypeFilter) {
               // alleen ophalen als dagsoortfilter actief en dagsoort ongelijk is aan het dagsoortfilter
              _ced_creweiland_list = CrewEilandday.getCrewEilandByDaytypeTime(_ced.day_type, "00:00");
           }
           let daytype_data = [];
           const day_start_end_time = _ced.delivery_time_short+'-'+_ced.pickup_time_short
           let crew_first_name = ((_ced_creweiland_list?.length>=1) ? " ("+_ced_creweiland_list[0].first_name+")" : "");
           daytype_data.push( m('.pull-left.daytype', _ced.day_type_name+crew_first_name ));
           daytype_data.push( m('.pull-right.start_end_time', day_start_end_time));
           item_data.push(m('.pull-left.list-data-max-width', daytype_data));
        })

        item_data.push(m('.pull-left.list-data-max-width', item_data_2)); // Gekaderde Activiteiten
        return item_data
    }

	view(vnode) {
        const _childeilandday = vnode.attrs.childeilandday;

        const child_item_route =  '#!/child/'+_childeilandday.child + '/eilandday/'+_childeilandday.id;
		return m('li.evg-list-item.media',
		          [ m('a',
		              { href: child_item_route },
                      [ m('.w2c-1',
                          m(ChildImgName, { child: _childeilandday.child_full,
                                          childeilandday: _childeilandday })),
                        m('.w2c-2.list-item-data', this.render_item_data(_childeilandday)),
                        // m('.w2c-2.list-item-data', this.render_item_data_new(_childeilandday)),
                      ])
		          ])
	}

}

module.exports = ChildEilanddayListItem
