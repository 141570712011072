var m = require("mithril")
var EvGService = require("../services/EvGService")
var Auth = require("../services/Auth")
var AppState = require("../services/AppState")
var Settings = require("../models/Settings")
var Breadcrumb = require("../components/Breadcrumb")
var OptionValueMap = require("../models/OptionValueMap")

class ChildFilterSelect {
    constructor(vnode) {
    }

    view(vnode) {
       return m('.filter-group',
                     // m( "div.group-title", "Filtering en Sortering op Kinderen"),
                     m( "div.evg_block",
                          [// m(".label-input",
                           //    m("label", "Groep"), m(".value.input",
                           //                           m(DaytypeDropdown,
                           //                             {dropdownType: 'childrelatedfilter'}))])),
                           m(".label-input",
                             m("label", "Sortering Kinderen"), m(".value.input", m(ChildOrderDropdown))),
                           // m(".label-input",
                           //   m("label", "Sortering Crew"), m(".value.input", m(CrewOrderDropdown)))
                          ] )
               )
    }
}


/**
 * krijgt settingslijst als argument,
 * na aanpassen van een setting, wordt een save knop actief.
 * als pagina verlaten, zonder te saven na aanpassen, moet dat via een bevestiging.
 **/
class SettingsData {
    constructor(vnode) {
    }

    view(vnode) {
        return m(".evg-block", "De lijs Met Knop")
    }
}

// Beheren van settings.
//
//
class SettingsPage {

    constructor(vnode) {
        //if(vnode.attrs.settingsList) {
        //    console.log("Voor een specefike pagina")
        const settings_crumb = { url: '/evg-settings',
                                 text: "Instellingen"};
        // console.log("Add Crumb: "+settings_crumb);
        Breadcrumb.addCrumb(settings_crumb);
        //} else {
        //    console.log("Voor de hele appicatie");
        //    const crumb = {  url: '/evg-settings',
        //                    text: "Module Instellingen"}
        //    Breadcrumb.setCrumbs([{url:'/menu', text:'Menu'},
        //                          crumb]); // is dus een soort van reset.
        //
        //}


       this.genereerSettingList = this.genereerSettingList.bind(this);
       this.genereerSettingSelector = this.genereerSettingSelector.bind(this);

    }


    genereerSettingSelector(opt_val, setting_value) {
        switch (opt_val.type) {
            case "text": return m('input', {name:opt_val.name,});
            case "dropdown": return m('select', {name:opt_val.name, onchange:Settings.setValueEvent},
                                      opt_val.options.map((option)=>{
                                           return m('option',
                                                    {value:option[0],
                                                     selected:(option[0]==setting_value?"selected":"")},
                                                    option[1]) })
                                     );
            default: return m('.error', "Value Type "+opt_val.type+" Niet geldig")
        }
    }


    // Als pageSettingsList is gezet, heeft een desbetreffende pagina een beperkte set aan settings nodig
    genereerSettingList(pageSettingList) {
        // console.log("genereerSettingList "+pageSettingList);
        let listToHandle = [];
        if(Settings.getSettingsSubList().length>0) {
            // console.info("Toon een subset van de settings");
            listToHandle = Settings.getSettingsSubList().map(setting_name => {return Settings.getSetting(setting_name)});
        } else {
            // console.info("Toon Alle settings");
            listToHandle = Settings.getAllSettings();
        }
        // console.log("listToHandle: "+listToHandle);
        let settingList = listToHandle.map((setting)=> {
            let opt_val = OptionValueMap.getOptionValue(setting.name);
            // console.log("OptVal: "+opt_val);
            if(opt_val) {
                return m(".evg_block", //.label_value",
                         m("label", opt_val.label),
                         m(".value.input", this.genereerSettingSelector(opt_val, setting.value))
                         )
            }
            return m('.evg_block',
                    m('label', "OPTIE "+setting.name),
                    m('.value', "Niet Gevonden"))
        })

        // console.log("settingsList: "+settingList);

        return settingList
    }


    // Als settingslist gezet en gevuld is, dan tonen we een subset van de Settings
    view(vnode) {
        m.render(document.getElementById("header"),
                 [m(Breadcrumb),
                  m("span.filter-date", AppState.get_filter_date())]);

        // console.log("vnode.state: "+vnode.state);
        // console.log("Settings.changed: "+Settings.changed );
        // console.log("Settings.getSettingsSubList(): "+Settings.getSettingsSubList() );

        Settings.setValue('bar', "Foo");

        return m("settings-page.evg_module",
                 m(".evg_block", //.label_value",
                         m("label", "Instelling"),
                         m("label", "Waarde")
                         ),
                 this.genereerSettingList(),
                 m(".evg_block",
                         m("button.evg_action "+(Settings.changed==true ? "menu_choice_action_required" : ""),
                          {onclick:Settings.store},
                           "Bewaren")),
                 )

    }

}

module.exports = SettingsPage
